<template>
  <div class="row">
    <div class="col-6">&nbsp;</div>
    <div class="col-6">
      <SearchText
          label="Search"
          @search-updated="handelSearch"
          @search-clear="handelSearchClear"
      />
    </div>
  </div>
  <strong>{{ searchData === null ? "Rule" : "Search" }} count</strong> |
  <span class="text-color-orange">{{ emailSchedulerListCompLength }}</span>
  <hr/>
  <div
      v-for="(item, index) in emailSchedulerListComp"
      :key="index"
      :class="['schedule-item', { '-inactive': item.active === '0' }]"
  >
    <div class="row">
      <div class="col-6">
        <p class="text-size-16">
          <strong>Send</strong> : {{ templateName(item.email) }}
        </p>
      </div>
      <div class="col-6">
        <input
            :id="`switch_${index}`"
            :checked="item.active === '1'"
            class="toggleSwitch"
            type="checkbox"
            @change="handleToggleChange(item.id)"
        /><label
          :for="`switch_${index}`"
          class="toggleLabel float-right"
      ></label>
      </div>
    </div>
    <div class="row schedule-item-inner d-flex flex-column align-items-start justify-content-lg-start">
      <div v-if="item.event" class="col-3">
        <span class="text-size-14 text-subdued"
        ><strong>ID</strong> : {{ item.id }}
        </span>
      </div>
      <div v-if="item.event" class="col-3">
        <span class="text-size-14"
        ><strong>Event</strong> :<br/>
          {{ deCamel(item.event) }}
        </span>
      </div>
      <div v-if="item.status_type" class="col-3">
        <span class="text-size-14"
        ><strong>Applies to</strong> :<br/>
          {{ deCamel(item.status_type) }}
        </span>
      </div>
      <div v-if="item.period" class="col-3">
        <span class="text-size-14"
        ><strong>Period</strong> :<br/>
          {{ item.period }}
        </span>
      </div>
      <div v-if="item.status_type && item.event === 'status-change'" class="col-3">
        <span class="text-size-14"
        ><strong>Status type</strong> :<br/>
          {{ getStatusType(item.status_type) }}
        </span>
      </div>
      <div v-if="item.on_status && item.event === 'status-change'" class="col-3">
        <span class="text-size-14"
        ><strong>On status</strong> :<br/>
          {{ item.on_status }}
        </span>
      </div>
      <div v-if="item.closed_reason" class="col-3">
        <span class="text-size-14"
        ><strong>Closed reason</strong> :<br/>
          {{ item.closed_reason }}
        </span>
      </div>
      <div v-if="item.after_email" class="col-3">
        <span class="text-size-14"
        ><strong>After email</strong> :<br/>
          {{ templateName(item.after_email) }}
        </span>
      </div>
      <div v-if="item.defined_status" class="col-3">
        <span class="text-size-14"
        ><strong>Send if status set to</strong> :<br/>
          {{ deCamel(item.defined_status) }}
        </span>
      </div>
      <div v-if="item.information_required" class="col-3">
        <span class="text-size-14"
        ><strong>Information required</strong> :<br/>
          {{ deCamel(item.information_required) }}
        </span>
      </div>
      <div v-if="item.created_at" class="col-3">
        <span class="text-size-14"
        ><strong>Created</strong> :<br/>
          {{ formatReadDateTime(item.created_at) }}
        </span>
      </div>
    </div>
    <!-- <div class="control mt-4">
      <div class="link-text text-color-red" @click="handleDelete(item.id)">delete</div>
    </div> -->
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
// import Pagination from "@/components/misc/Pagination";

export default {
  name: "EmailSchedulerList",
  data: () => ({
    emailSchedulerList: null,
    searchData: null,
    searchText: "",
    links: null,
    meta: null
  }),
  components: {
    // Pagination
  },
  computed: {
    ...mapGetters(["getEmailTemplatesWithLetters"]),
    emailSchedulerListComp() {
      return this.searchData || this.emailSchedulerList;
    },
    emailSchedulerListCompLength() {
      return this.searchData?.length || this.emailSchedulerListComp?.length;
    }
  },
  methods: {
    ...mapActions([
      "fetchEmailSchedulerList",
      "searchScheduledEmails",
      "toggleEmailScheduleActive",
      "deletedEmailSchedule"
    ]),
    getStatusType(text) {
      let value = null;
      switch (text) {
        case 'application':
          value = 'Application';
          break;
        case 'pensiontrace':
          value = 'Traced Pension';
          break;
      }
      return value;
    },
    handleUpdate(data) {
      this.emailSchedulerList = data.data.data;
      this.links = data.data.links;
      this.meta = data.data.meta;
    },
    templateName(template) {
      let name = null;
      for (let i in this.getEmailTemplatesWithLetters) {
        let templateObject = this.getEmailTemplatesWithLetters[i];
        if (templateObject.id === template) {
          name = templateObject.name;
        }
      }

      return name;
    },
    async handleToggleChange(id) {
      await this.toggleEmailScheduleActive({id}).catch(err =>
          console.log(err)
      );
      if (this.searchText !== "") {
        this.handelSearch(this.searchText);
      } else {
        this.fetchScheduleData();
      }
    },
    async handleDelete(id) {
      if (!confirm("Delete this email schedule?")) return;
      let result = await this.deletedEmailSchedule({id}).catch(err =>
          console.log(err)
      );
      this.emailSchedulerList = result.data.result;
    },
    async handelSearch(value) {
      if (value === "") {
        this.searchText = null;
      }
      this.searchText = value;
      await this.debounce(async () => {
        let response = await this.searchScheduledEmails({
          value
        }).catch(err => console.log(err));
        if (response.data.result.length > 0) {
          this.searchData = response.data.result;
        } else {
          this.searchData = null;
        }
      });
    },
    handelSearchClear() {
      this.searchText = null;
    },
    async fetchScheduleData() {
      // add caching??
      let result = await this.fetchEmailSchedulerList().catch(err =>
          console.log(err)
      );
      if (!result) return;
      this.emailSchedulerList = result.data.data;
      this.links = result.data.links;
      this.meta = result.data.meta;
    }
  },
  async beforeMount() {
    await this.fetchScheduleData();
  }
};
</script>
<style lang="scss">
.schedule-item {
  border-radius: $borderRadius;
  padding: $spacingBase;
  background-color: $lightergrey;
  margin-bottom: calc(#{$spacingBase} / 2);
  width: 100%;

  &.-inactive {
    opacity: 0.3;
  }

  &-inner {
    border-radius: $borderRadius;
    background-color: white;
    padding: calc(#{$spacingBase} / 2);
    height: 200px;

    div[class^="col-"] {
      margin-bottom: $spacingBase;
    }
  }

  .control {
  }
}
</style>
