<template>
  <Form class="w-100 text-left" @submit="saveForm">
    <SelectInput
        name="email"
        label="Send email template"
        :with-blank-option="true"
        :options="getEmailTemplatesWithLetters"
        @selected="templateSelected"
    />

    <div v-if="showOptions">
      <div v-for="i in range" :key="i">
        <ScheduleFormFields
            :ind="i"
            :get-email-template-filter="getEmailTemplatesWithLetters"
            :get-status-list="getStatusList"
        />
      </div>
      <span class="link-text" @click="handleAddRule">add rule</span>
      <button class="fabric-btn fabric-btn-submit">create</button>
    </div>
  </Form>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import ScheduleFormFields from "@/components/email-manager/ScheduleFormFields";

export default {
  name: "EmailSchedulerForm",
  data: () => ({
    showOptions: false,
    emailTemplate: false,
    excludeList: [],
    range: 1,
  }),
  components: {
    ScheduleFormFields
  },
  computed: {
    ...mapGetters([
      "getEmailTemplatesWithLetters",
      "getStatusAppValues",
      "getStatusTPValues",
      "getClosedConditions",
    ]),
    filteredEmailTemplates() {
      // if (this.excludeList.length === 0) return;
      return this.getEmailTemplatesWithLetters.filter(
          item => !this.excludeList.includes(item.id)
      );
    },
    getEmailTemplateFilter() {
      return this.getEmailTemplatesWithLetters.filter(
          item => item.id !== this.emailTemplate
      );
    },
    getStatusList() {
      let appStatTemp = this.getStatusAppValues.map((i) => {
        return {
          'id': `application | ${i.id}`,
          'text': `Application: ${i.text}`
        }
      });
      let tpStatTemp = this.getStatusTPValues.map((i) => {
        return {
          'id': `pensiontrace | ${i.id}`,
          'text': `Traced Pension: ${i.text}`
        }
      });
      return (appStatTemp).concat(tpStatTemp);
    }
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(["addEmailSchedule", "fetchEmailSchedulerList"]),
    keepValue(string, int) {
      if (!string) return;
      let valArr = string.split('|');
      if (valArr[int]) {
        return valArr[int].trim();
      } else {
        return string;
      }
    },
    handleAddRule() {
      this.range++;
    },
    async saveForm(params) {
      let tempParams = [];
      console.log(params);
      for (let i = 0; i < this.range; i++) {
        let ind = i + 1;
        let statusType = null;

        if (typeof params["defined_status_" + ind] !== 'undefined') {
          statusType = (this.keepValue((params["defined_status_" + ind]).toLowerCase(), 0))?.includes("application") ? 'application' : 'pensiontrace'
        }

        if (typeof params["on_status_" + ind] !== 'undefined') {
          statusType = (this.keepValue((params["on_status_" + ind]).toLowerCase(), 0))?.includes("application") ? 'application' : 'pensiontrace'
        }

        tempParams.push({
          after_email: params["after_email_" + ind],
          email: params["email"],
          event: params["event_" + ind],
          on_status: this.keepValue(params["on_status_" + ind], 1),
          defined_status: this.keepValue(params["defined_status_" + ind], 1),
          closed_reason: params["closed_reason_" + ind],
          information_required: params["information_required_" + ind],
          period: params["period_" + ind],
          status_type: statusType,
        });
      }

      let response = await this.addEmailSchedule(tempParams).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error + "!"
        });
      });
      if (response) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Schedule added successfully!"
        });
        this.$parent.page = "Scheduled emails";
      }
    },
    templateSelected(value) {
      this.emailTemplate = value;
      this.showOptions = value !== "";
    },
    async fetchScheduleData() {
      let result = await this.fetchEmailSchedulerList().catch(err =>
          console.log(err)
      );
      if (!result) return;
      this.excludeList = result.data.data.map(i => i.email);
    }
  },
  async beforeMount() {
    await this.fetchScheduleData();
  }
};
</script>
<style lang="scss"></style>
