<template>
  <div class="container-fluid h-auto">
    <BreadCrumb
        :links="[
          {
            name: 'Email Manager'
          },
          {
            name: 'Email Rule Manager',
            params: {
              uuid: $route.params.uuid
            }
          }
        ]"
    />
    <div class="row">
      <div class="col-3">
        <SubMenu>
          <SubMenuLink
            link-name="Scheduled emails"
            :icon-src="list"
            :page="page"
            @navigate="handleNavigate"
          />
          <SubMenuLink
            link-name="Create schedule"
            :icon-src="clock"
            :page="page"
            @navigate="handleNavigate"
          />
        </SubMenu>
      </div>
      <div class="col-9">
        <div class="card mb-4">
          <div class="card-body text-left">
            <EmailSchedulerForm v-if="page === 'Create schedule'" />
            <EmailSchedulerList v-if="page === 'Scheduled emails'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubMenu from "@/components/menu/SubMenu";
import SubMenuLink from "@/components/menu/SubMenuLink";
import EmailSchedulerForm from "@/components/form/EmailSchedulerForm";
import EmailSchedulerList from "@/components/lists/EmailSchedulerList.vue";
import list from "@fa/regular/list-alt.svg";
import clock from "@fa/regular/clock.svg";

export default {
  name: "EmailScheduler",
  components: {
    SubMenu,
    SubMenuLink,
    EmailSchedulerForm,
    EmailSchedulerList
  },
  data: () => ({
    page: "Scheduled emails",
    list,
    clock
  }),
  methods: {
    handleNavigate(page) {
      this.page = page;
    }
  }
};
</script>
<style lang="scss"></style>
