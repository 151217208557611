<template>
  <hr/>
  <p>
    <strong>Rule {{ ind }}:</strong>
  </p>
  <SelectInput
      :name="defineName('event')"
      :options="[
      {
        id: 'delayed-send',
        text: 'Delayed send'
      },
      {
        id: 'status-change',
        text: 'On status change'
      },
      {
        id: 'after-email',
        text: 'After email'
      }
    ]"
      label="Event type"
      placeholder=""
      @selected="handleEventType"
  />
  <TextInput
      v-if="eventType === 'delayed-send' || eventType === 'status-change'"
      :name="defineName('period')"
      label="Period ( days )"
      placeholder="3"
      type="text"
      @updated="handleShowAllStatus"
  />
  <SelectInput
      v-if="eventType === 'status-change'"
      :name="defineName('on_status')"
      :options="showAllStatus ? getStatusList : getStatusTPValues"
      label="On status"
      @selected="handleStatusValue"
  />
  <SelectInput
      v-if="eventType === 'status-change' && ( selectedStatus === 'pensiontrace | complete' || selectedStatus === 'complete' )"
      :name="defineName('closed_reason')"
      :options="getStatusClosedReason"
      label="Closed reason"
  />
  <SelectInput
      v-if="eventType === 'delayed-send' || eventType === 'after-email'"
      :name="defineName('after_email')"
      :options="getEmailTemplateFilter"
      label="After email template"
  />
  <SelectInput
      v-if="eventType === 'delayed-send' || eventType === 'after-email'"
      :name="defineName('defined_status')"
      :options="getStatusList"
      label="If status is set to"
  />
  <SelectInput
      v-if="
      eventType === 'delayed-send' ||
        eventType === 'status-change' ||
        eventType === 'after-email'
    "
      :name="defineName('information_required')"
      :options="informationRequired"
      label="Information required"
  />
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "ScheduleFormFields",
  props: {
    ind: {
      default: null
    },
    getEmailTemplateFilter: {
      default: null
    },
    getStatusList: {
      default: null
    },
  },
  data: () => ({
    eventType: null,
    selectedStatus: null,
    showAllStatus: false,
  }),
  computed: {
    ...mapGetters([
      "getStatusTPValues",
      "getStatusClosedReason",
    ]),
    informationRequired() {
      switch (this.eventType) {
        case "status-change":
          return [
            {
              id: "yes",
              text: "Yes"
            },
            {
              id: "no",
              text: "No"
            },
            {
              id: "previous_content",
              text: "Previous content"
            }
          ];
        default:
          return [
            {
              id: "no",
              text: "No"
            },
            {
              id: "previous_content",
              text: "Previous content"
            }
          ];
      }
    },
  },
  methods: {
    handleShowAllStatus(value) {
      this.showAllStatus = (value > 0);
    },
    handleEventType(value) {
      this.eventType = value;
    },
    handleStatusValue(value) {
      this.selectedStatus = value;
    }
  },
};
</script>
<style lang="scss"></style>
